var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-col",
        { attrs: { cols: "12", xl: "12" } },
        [
          _c(
            "transition",
            { attrs: { name: "slide" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "6" } }, [
                            _c("h4", [_vm._v(_vm._s(_vm.rendTitle))]),
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "pull-right", attrs: { cols: "6" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn btn-primary btn-md float-right",
                                  style: `${
                                    !_vm.$screen.md ? "width:100%" : ""
                                  }`,
                                  attrs: { variant: "success", size: "md" },
                                  on: { click: _vm.backToTaskList },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        this.FormMSG(15, "Back to tasks")
                                      ) +
                                      " "
                                  ),
                                  _c("i", { staticClass: "icon-action-undo" }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1", attrs: { cols: "6" } },
                            [
                              _vm.$screen.width >= 992
                                ? _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        label: _vm.FormMSG(2, "Filter"),
                                        "label-cols": "3",
                                        "label-align-sm": "left",
                                        "label-size": "sm",
                                        "label-for": "filterInput",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { size: "sm" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "search",
                                              id: "filterInput",
                                              placeholder: this.FormMSG(
                                                3,
                                                "Type to Search"
                                              ),
                                            },
                                            model: {
                                              value: _vm.filter,
                                              callback: function ($$v) {
                                                _vm.filter = $$v
                                              },
                                              expression: "filter",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    disabled: !_vm.filter,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.filter = ""
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-times",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-md float-right",
                                      attrs: {
                                        block: !_vm.$screen.md,
                                        variant: "success",
                                        size: "md",
                                      },
                                      on: { click: _vm.newTaskType },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(4, "New Task Type")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.$screen.width < 992
                                ? _c("card-list-builder", {
                                    attrs: {
                                      items: _vm.taskTypes,
                                      fields: _vm.taskTypeMobileFields,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function (data) {
                                            return [
                                              data.item.isSystem ||
                                              data.item.picture !== ""
                                                ? _c("div", [
                                                    _c("img", {
                                                      staticClass:
                                                        "task-type-image",
                                                      staticStyle: {
                                                        width: "26px",
                                                        height: "26px",
                                                      },
                                                      attrs: {
                                                        src: _vm.getImage(
                                                          data.item
                                                        ),
                                                        alt: "photo",
                                                      },
                                                    }),
                                                  ])
                                                : _vm._e(),
                                              data.item.description.length > 0
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticStyle: {
                                                            "font-size":
                                                              "1.5rem",
                                                            "line-height":
                                                              "20px",
                                                          },
                                                          attrs: {
                                                            id: `task-type-${parseInt(
                                                              data.item.id,
                                                              10
                                                            )}`,
                                                            size: "sm",
                                                            variant: "light",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-list",
                                                            staticStyle: {
                                                              color:
                                                                "rgba(0, 0, 0, 0.425)",
                                                            },
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target: `task-type-${parseInt(
                                                              data.item.id,
                                                              10
                                                            )}`,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "title",
                                                                fn: function () {
                                                                  return [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          21,
                                                                          "Description"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                data.item
                                                                  .description
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editType(
                                                        data.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "icon-pencil",
                                                  }),
                                                ]
                                              ),
                                              !data.item.isSystem
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "danger",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteType(
                                                            data.item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-trash",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      854232408
                                    ),
                                  })
                                : _vm._e(),
                              _vm.$screen.width >= 992
                                ? _c("b-table", {
                                    ref: "taskTypeTable",
                                    attrs: {
                                      "sticky-header": "700px",
                                      hover: _vm.hover,
                                      items: _vm.taskTypes,
                                      fields: _vm.taskTypeFields,
                                      "current-page": _vm.currentPage,
                                      "per-page": _vm.perPage,
                                      "head-variant": _vm.hv,
                                      filter: _vm.filter,
                                      responsive: "sm",
                                      bordered: "",
                                      small: "",
                                      "empty-text": _vm.FormMSG(
                                        14,
                                        "No type found"
                                      ),
                                      "show-empty": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(description)",
                                          fn: function (data) {
                                            return [
                                              data.item.description.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.right",
                                                          value: `${data.item.description}`,
                                                          expression:
                                                            "`${data.item.description}`",
                                                          modifiers: {
                                                            hover: true,
                                                            right: true,
                                                          },
                                                        },
                                                      ],
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          21,
                                                          "Description"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.truncate(
                                                              data.item
                                                                .description,
                                                              15
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(picture)",
                                          fn: function (data) {
                                            return [
                                              data.item.isSystem ||
                                              data.item.picture !== ""
                                                ? _c("div", [
                                                    _c("img", {
                                                      staticClass:
                                                        "task-type-image",
                                                      attrs: {
                                                        src: _vm.getImage(
                                                          data.item
                                                        ),
                                                        alt: "photo",
                                                      },
                                                    }),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(edit)",
                                          fn: function (data) {
                                            return [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "btn bg-transparent border-0",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editType(
                                                        data.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.EDIT.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.EDIT.color,
                                                        size: 20,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(delete)",
                                          fn: function (data) {
                                            return [
                                              !data.item.isSystem
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "danger",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteType(
                                                            data.item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.TRASH.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.TRASH
                                                                .color,
                                                            size: 20,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      787517991
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }